import React, { useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { theme as themeStyles } from "../../styles/theme-constants";
import { ThemeStyles } from "../themeStyles";
import { phoneAreaCodes } from "../../constants/phoneAreaCodes";
import { store } from "../../context/store";
import { DATA_STEP, allValuesInFormAreValid } from "../../lib/signup";
import { signupStyles } from "./styles";

export const DataFormStep = ({ formik }) => {
  const { dispatch } = useContext(store);
  const classes = signupStyles();

  useEffect(() => {
    const formHasErrors = Boolean(formik.errors.firstname)
      || Boolean(formik.errors.paternal_lastname)
      || Boolean(formik.errors.maternal_lastname)
      || Boolean(formik.errors.calling_country_code)
      || Boolean(formik.errors.user_phone);

    allValuesInFormAreValid({
      dispatch,
      formHasErrors: formHasErrors,
      formName: DATA_STEP,
    });
  }, [formik.errors]);

  const setFieldWithCapitalized = (event) => {
    const capitalizedValue = capitalizeFirstLetter(event.target.value);
    formik.setFieldValue(event.target.name, capitalizedValue);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div style={{ minHeight: '70vh' }}>
      <div className={classes.paddedLogoContainer}>
        <img className={classes.imageContainer} src={`${process.env.CDN_HOST}/vinco_logo.png`} />
      </div>
      <Typography component="h1" variant="h5" style={{ paddingTop: '10%' }}>
        Cuéntanos un poco más sobre ti
      </Typography>
      <Typography variant="overline" gutterBottom>
        ¿Cuál es tu nombre completo?
      </Typography>
      <form noValidate style={{ marginBottom: '1rem', marginTop: '2rem' }}>
        <ThemeStyles color={themeStyles.secondary}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                autoFocus
                id="firstname"
                label="Nombre"
                name="firstname"
                autoComplete="off"
                onChange={(e) => setFieldWithCapitalized(e)}
                onBlur={formik.handleBlur}
                placeholder="Roberto"
                InputLabelProps={{
                  shrink: true, classes: {
                    root: classes.awesomeLabel
                  }
                }}
                InputProps={{
                  classes: { input: classes.capitalizeInput },
                }}
                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                value={formik.values.firstname}
                helperText={formik.touched.firstname && formik.errors.firstname ? formik.errors.firstname : null }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="paternal_lastname"
                label="Primer apellido"
                name="paternal_lastname"
                placeholder="Gómez"
                InputLabelProps={{
                  shrink: true, classes: {
                    root: classes.awesomeLabel
                  }
                }}
                onChange={(e) => setFieldWithCapitalized(e)}
                onBlur={formik.handleBlur}
                autoComplete="off"
                InputProps={{
                  classes: { input: classes.capitalizeInput }
                }}
                error={formik.touched.paternal_lastname && Boolean(formik.errors.paternal_lastname)}
                value={formik.values.paternal_lastname}
                helperText={formik.touched.paternal_lastname && formik.errors.paternal_lastname ? formik.errors.paternal_lastname : null }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="maternal_lastname"
                label="Segundo apellido"
                name="maternal_lastname"
                placeholder="Bolaños"
                InputLabelProps={{
                  shrink: true, classes: {
                    root: classes.awesomeLabel
                  }
                }}
                onChange={(e) => setFieldWithCapitalized(e)}
                onBlur={formik.handleBlur}
                autoComplete="off"
                InputProps={{
                  classes: { input: classes.capitalizeInput }
                }}
                error={formik.touched.maternal_lastname && Boolean(formik.errors.maternal_lastname)}
                value={formik.values.maternal_lastname}
                helperText={formik.touched.maternal_lastname && formik.errors.maternal_lastname ? formik.errors.maternal_lastname : null }
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.containerPhone}
            >
              <TextField
                className={classes.textCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
                select
                required
                label="Código de país"
                name="calling_country_code"
                variant="outlined"
                error={formik.touched.calling_country_code && Boolean(formik.errors.calling_country_code)}
                SelectProps={{
                  native: true,
                }}
                inputProps={{
                  name: "calling_country_code",
                }}
                value={formik.values.calling_country_code}
                helperText={formik.touched.calling_country_code && formik.errors.calling_country_code ? formik.errors.calling_country_code : null }
              >
                <option aria-label="None" value="" >
                  Código de país
                </option>
                {phoneAreaCodes.map((option, i) => (
                  <option
                    key={i}
                    value={option.dial_code}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
              <TextField
                fullWidth
                required
                className={classes.textPhone}
                label="Teléfono"
                name="user_phone"
                error={formik.touched.user_phone && Boolean(formik.errors.user_phone)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{
                    formik.values.calling_country_code
                  }</InputAdornment>,
                }}
                inputProps={{ maxLength: 14 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
                placeholder={'8888888888'}
                variant="outlined"
                value={formik.values.user_phone}
                helperText={formik.touched.user_phone && formik.errors.user_phone ? formik.errors.user_phone : null }
              />
            </Grid>
          </Grid>
        </ThemeStyles>
      </form>
      <Typography variant="overline">
        Paso 2 de 3
      </Typography>
    </div>
  );
}