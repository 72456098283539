import { loginErrorMessages } from './loginErrors';
import { resetPasswordMessages } from './resetPasswordErrors';
import { signupErrorMessages } from './signupErrorMessages';

export const returnMessageError = (error) => {
  let errorMessageId = null;
  if(error?.data) {
    try {
      errorMessageId = error.data[0].messages[0].id;
    } catch(err) {
      // most probably: Cannot read property 'messages' of undefined
      console.error(err);
    }
    return {
      ...loginErrorMessages,
      ...resetPasswordMessages,
      ...signupErrorMessages
    }[errorMessageId];
    
  } else {
    return "Otro error ha sucedido. Favor de contactar a soporte@vincoed.com."

  }

  
}