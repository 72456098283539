import React, { useEffect, useContext } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { signupStyles, CenterContainer } from "./styles";
import GeneralButton from '../GeneralButton';
import Link from '@material-ui/core/Link';

import { useRouter } from "next/router";

import { store } from "../../context/store";
import { closeSnackbar } from "../../context/actions";

export const ThankyouStep = () => {
  const { dispatch } = useContext(store);

  const classes = signupStyles();
  const router = useRouter();

  const goLogin = () => {
    router.push('/login');
  }

  useEffect(() => {
    closeSnackbar(dispatch);
  }, []);


  return (
    <div className={classes.centerContainer}>
      <CssBaseline />
      <div className={classes.paddedLogoContainer} style={{ paddingTop: '40%', marginBottom: '2rem' }}>
        <img className={classes.bigImageContainer} src={`${process.env.CDN_HOST}/vinco_logo.png`} />
      </div>
      <div className={classes.thankyouMessage}>
        <Typography component="h1" variant="h3">
          ¡Felicidades!
        </Typography>
      </div>
      <CheckCircleIcon
        className={classes.icon}
        style={{ fill: '#009888' }}
        fontSize="large" />
      <div className={classes.subtitle}>
        <Typography component="h3" className={classes.thankyouLabel} >
        Tu cuenta ha sido creada
          con éxito.{` `}
        </Typography>
      </div>
      <GeneralButton
        button={{
          text: "Inicia sesión",
          type: "go",
          func: () => goLogin()
        }} />
    </div>
  )
}