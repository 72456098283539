import { createUser, handleCreateUserResponse } from '../lib/users';
import { hash } from './hash';

import {
  isLoading,
  handleSignupNext,
  handleSignupBack,
  openSnackbar,
  setSignupFormValidation
} from '../context/actions';
import {
  WelcomeStep,
  CompanyFormStep,
  DataFormStep,
  AccountStep,
  ThankyouStep
} from "../components/SignupFormSteps";

export const WELCOME_STEP = 'WELCOME_STEP';
export const COMPANY_STEP = 'COMPANY_STEP';
export const DATA_STEP = 'DATA_STEP';
export const ACCOUNT_STEP = 'ACCOUNT_STEP';
export const THANKYOU_STEP = 'THANKYOU_STEP'

const stepObjects = {
  WELCOME_STEP: {
    id: 0,
    name: WELCOME_STEP
  },
  COMPANY_STEP: {
    id: 1,
    name: COMPANY_STEP
  },
  DATA_STEP: {
    id: 2,
    name: DATA_STEP
  },
  ACCOUNT_STEP: {
    id: 3,
    name: ACCOUNT_STEP
  },
  THANKYOU_STEP: {
    id: 4,
    name: THANKYOU_STEP
  }

}

export function getSteps() {
  return [
    {
      ...stepObjects.WELCOME_STEP
    }, {
      ...stepObjects.COMPANY_STEP
    }, {
      ...stepObjects.DATA_STEP
    }, {
      ...stepObjects.ACCOUNT_STEP
    }, {
      ...stepObjects.THANKYOU_STEP
    }
  ];
}

export function getStepContent(step, formik) {
  switch (step.name) {
    case WELCOME_STEP:
      return <WelcomeStep formik={formik} />;
    case COMPANY_STEP:
      return <CompanyFormStep formik={formik} />;
    case DATA_STEP:
      return <DataFormStep formik={formik} />;
    case ACCOUNT_STEP:
      return <AccountStep formik={formik} />;
    case THANKYOU_STEP:
      return <ThankyouStep formik={formik} />;
    default:
      return <ThankyouStep formik={formik} />;
  }
}

export function actionsForNextButton({ stepName, dispatch, isFormValid }) {
  if (!isFormValid) {
    openSnackbar(dispatch, {
      autoHideDuration: 3000,
      isOpen: true,
      message: 'Por favor llena correctamente el formulario para seguir avanzando',
      severity: 'error'
    });
  } else {
    switch (stepName) {
      case WELCOME_STEP:
        nextStep({ dispatch, step: stepObjects.COMPANY_STEP });
        break;
      case COMPANY_STEP:
        nextStep({ dispatch, step: stepObjects.DATA_STEP });
        break;
      case DATA_STEP:
        nextStep({ dispatch, step: stepObjects.ACCOUNT_STEP })
        break;
    }
  }


}

export const finishCreation = (dispatch) => {
  nextStep({ dispatch, step: stepObjects.THANKYOU_STEP });
}
const nextStep = ({ dispatch, step }) => {
  handleSignupNext(dispatch, { step: step })
}

const backStep = ({ dispatch, step }) => {
  handleSignupBack(dispatch, { step: step })
}

export function actionsForBackButton({ stepName, dispatch }) {
  switch (stepName) {
    case WELCOME_STEP:
      break;
    case COMPANY_STEP:
      backStep({ dispatch, step: stepObjects.WELCOME_STEP });
      break;
    case DATA_STEP:
      backStep({ dispatch, step: stepObjects.COMPANY_STEP })
      break;
    case ACCOUNT_STEP:
      backStep({ dispatch, step: stepObjects.DATA_STEP })
      break;
  }
}

export function allValuesInFormAreValid({ formName, formHasErrors, dispatch }) {
  formHasErrors ?
    setSignupFormValidation(dispatch, { name: formName, value: false }) :
    setSignupFormValidation(dispatch, { name: formName, value: true })
}

export async function newUser({ dispatch, formInfo }) {
  isLoading(dispatch, { loading: true });

  const date = new Date();
  const currentDate = date.toISOString();
  const password = await hash(formInfo.password.trim())

  const userInfo = {
    company: formInfo.company,
    user_type: formInfo.employee_families,
    first_name: formInfo.firstname.trim(),
    paternal_lastname: formInfo.paternal_lastname.trim(),
    maternal_lastname: formInfo.maternal_lastname.trim(),
    user_phone: formInfo.user_phone,
    calling_country_code: formInfo.calling_country_code,
    fullname: formInfo.firstname.trim() + " " + formInfo.paternal_lastname.trim() + " " + formInfo.maternal_lastname.trim(),
    username: formInfo.username.trim(),
    password: password,
    email: formInfo.email.trim(),
    isTermsAccepted: true,
    accepted_terms_timestamp: currentDate,
    internal_company_id: formInfo.internal_company_id.trim(),
    ...formInfo
  };
  userInfo.password = password
  const response = await createUser(userInfo);
  
  handleCreateUserResponse(dispatch, response);

}

export function hideEmail(email) {
  const [username, domain] = email.split('@');
  const hiddenUsername = username.substring(0, 2) + '*'.repeat(username.length - 2);
  const hiddenDomain = '*'.repeat(domain.length -2) + domain.substring(domain.lastIndexOf('.') - 2);
  return hiddenUsername + hiddenDomain;
}
