import React, { useRef, useState, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { theme as themeStyles } from "../../styles/theme-constants";
import { ThemeStyles } from "../themeStyles";
import { store } from "../../context/store";
import { COMPANY_STEP, allValuesInFormAreValid } from "../../lib/signup";
import { signupStyles } from "./styles";
import Link from '@material-ui/core/Link';
import { getSignupPagesCompaniesConfig } from "../../lib/api";
import { isLoading } from "../../context/actions";

export const CompanyFormStep = ({ formik }) => {
  const { state, dispatch } = useContext(store);
  const ref = useRef();
  const classes = signupStyles();
  const {signupReducer: {companiesAtDropdown,userTypes}} = state;

  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [notPartnerCompany, setNotPartnerCompany] = useState("");
  const [selectedRelationship, setSelectedRelationship] = useState("");
  const [companySelected, setCompanySelected] = useState(null);
  const [customLabel, setCustomLabel] = useState([]);
  const {errors,values} = formik;

  useEffect(() => {
    let formHasErrors = true;
    if(customLabel?.length >=1){
      //get values
      for (let index = 0; index < customLabel.length; index++) {
        const element = customLabel[index];
        const value = values[element?.name] ? values[element?.name] : '';
        const isValidCustomInput =  validateCustomInputs({value,regEx:element?.regex});
        if(isValidCustomInput){
          formHasErrors =  true;
        }else{
          formHasErrors = Boolean(formik.errors.company) || Boolean(formik.errors.employee_families) || Boolean(formik.errors.internal_company_id);
        }
        
      }
      
    }else{
      formHasErrors = Boolean(formik.errors.company) || Boolean(formik.errors.employee_families) || Boolean(formik.errors.internal_company_id);
    }

    allValuesInFormAreValid({
      dispatch,
      formName: COMPANY_STEP,
      formHasErrors: formHasErrors,
    });
  }, [formik?.values]);

  useEffect(() => {
    userTypes.sort(function (a, b) {
      return (a.id - b.id)
    });
  }, []);

  const getOptionSelected = (option, value) => {
    return option.id === value.id;
  }

  const changeDropdownValue = async(value) => {
    if(value === null){
      formik.setFieldValue('internal_company_id', "");
      formik.setFieldValue('employee_families', "");
      formik.setFieldValue('company', null);
      setCustomLabel([]);
      return;
    }
    if(value){
      setNotPartnerCompany('');
      await onSelectCompany(value);
    }

  }

  const changeSelectFamilies = (value) => {
    formik.setFieldValue('employee_families', value);
    const selectedEmployeeType = userTypes.find(type => type.id === parseInt(value));
    handleChangeLabelEmployeeId({ selected: selectedEmployeeType });
    setTimeout(() => {
      ref.current.firstElementChild.blur()
    }, 0);
  }

  const handleChangeLabelEmployeeId = ({ selected }) => {
    const config = companySelected?.config;
    let labelEmployeeId = config && config?.labelNames && config?.labelNames['internal_company_id'] ? config.labelNames['internal_company_id'] : "Número de empleado";

    if (selected?.id !== 1) {
      labelEmployeeId += " de familiar empleado/a"
    }
    setSelectedRelationship(labelEmployeeId);
  }


  const willOpen = (value) => {
    if (value.length >= 2) {
      setOpen(true);
    } else {
      formik.values.company !== null && formik.setFieldValue('company', null);
      setOpen(false);
    }
  }


  const onEnterKeyPress = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    setCompanies(companiesAtDropdown);
  }, [companiesAtDropdown]);

  const handleChangeEmployeeId = async(id) => {
    formik.setFieldValue('internal_company_id', id);
  }


  const handleChangeCustomLabel = (name,value) => {
    formik.setFieldValue(name, value);
  }

  const onSelectCompany = async(value) =>{

    isLoading(dispatch, { loading: true });
    
    try {
      const responseCompanyConfig = await getSignupPagesCompaniesConfig(value?.id);

      if(responseCompanyConfig?.status === 200){
        const config = responseCompanyConfig?.data[0]?.config;
        if(config?.customAttributes?.length >= 1){
            await setCustomInputs(config?.customAttributes);
        }else{
            setCustomLabel([]);
        }
        //set company select
        formik.setFieldValue('company', {comp_name:responseCompanyConfig?.data[0]?.comp_name,id:responseCompanyConfig?.data[0]?.id,config:config});
        const labelEmployeeId = config && config.labelNames && config.labelNames['internal_company_id'] ? config.labelNames['internal_company_id'] : "Número de empleado";
        setSelectedRelationship(labelEmployeeId);
        setCompanySelected(responseCompanyConfig?.data[0]);


      }else{
        formik.setFieldValue('internal_company_id', "");
        formik.setFieldValue('employee_families', "");
        formik.setFieldValue('company', null);
        setCustomLabel([]);
      }

    } catch (error) {
        console.error(JSON.stringify(error,null,3));
    }

    isLoading(dispatch, { loading: false });

  }


  /**
   * This function sets custom input fields based on provided labels and regex patterns, and only
   * includes those that should be shown on sign up.
   */
  const setCustomInputs = (customLabels) => {
    isLoading(dispatch, { loading: true });
    let inputs = [];
    for (let index = 0; index < customLabels?.length; index++) {
      const custom = customLabels[index];
        if(custom?.showOnSignUp && custom?.key !== ''){
        inputs.push({name:custom?.key,label:custom?.label,RegExp,regEx:custom?.regex,dataType:custom?.dataType,showOnSignUp:custom?.showOnSignUp});
      }
    }

    setCustomLabel(inputs);
    isLoading(dispatch, { loading: false });
  }


  const validateCustomInputs = ({value = '',regEx = null}) =>{

    let inputValue = value?.trim();
    let isValid = true;

    if(inputValue !== ''){
      
      isValid = false;
      
      if(regEx){
        const isValidRegEx = new RegExp(regEx);
        if(!isValidRegEx?.test(inputValue)){
          isValid = true;
        }
      }
      
    }

    return isValid;

  } 


  return (
    <div style={{ minHeight: '70vh' }}>
      <div className={classes.paddedLogoContainer}>
        <img className={classes.imageContainer} src={`${process.env.CDN_HOST}/vinco_logo.png`} />
      </div>
      <Typography component="h1" variant="h5" style={{ paddingTop: '10%' }}>
        Coloca los siguientes datos
      </Typography>
      <Typography variant="overline" gutterBottom>
        Esto nos ayudará a ofrecerte la mejor experiencia.
      </Typography>
      <form noValidate style={{ marginBottom: '1rem', marginTop: '2rem' }}>
        <ThemeStyles color={themeStyles.secondary}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={formik.values.company}
                id="company"
                includeInputInList
                open={open}
                classes={{ endAdornment: classes.noAdornment }}
                style={{ marginBottom: '10px', fontSize: '32px !important' }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, value) => {
                  changeDropdownValue(value)
                }}
                options={companies}
                getOptionSelected={getOptionSelected}
                getOptionLabel={(option) => option.comp_name ? option.comp_name : ''}
                noOptionsText="Sin resultados"
                renderInput={(params) => <TextField
                  {
                  ...params
                  }
                  variant="outlined"
                  required
                  fullWidth
                  label="Busca tu empresa/organización"
                  autoFocus
                  error={formik.touched.company && Boolean(formik.errors.company)}
                  onChange={(event) => {
                    willOpen(event.target.value)
                  }}
                  helperText={
                    formik.touched.company && Boolean(formik.errors.company) && formik.errors.company
                  }
                  onKeyDown={(e) => onEnterKeyPress(e)}
                  InputLabelProps={{
                    shrink: true, classes: {
                      root: classes.awesomeLabel
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
                }
              />
              {
                !!notPartnerCompany && <Typography variant="overline" color="secondary" style={{ lineHeight: 1 }} >
                  {notPartnerCompany} aún no trabaja con Vinco. Haz <Link underline="always" href="https://www.vincoed.com/contactanos.html">clic aquí</Link> si deseas
                  información para tu empresa/organización.
                </Typography>
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                disabled={Boolean(formik.errors.company)}
                label="Elige tu asociación con la empresa/organización"
                name="employee_families"
                error={formik.touched.employee_families && Boolean(formik.errors.employee_families)}
                onChange={(e) => changeSelectFamilies(e.target.value)}
                onBlur={formik.handleBlur}
                select
                SelectProps={{
                  native: true,
                  ref: ref,
                }}
                helperText={
                  formik.touched.employee_families && Boolean(formik.errors.employee_families) && formik.errors.employee_families
                }
                value={formik.values.employee_families}
                variant="outlined"
              >
                <option aria-label="None" value="" />
                {userTypes.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.type_name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={selectedRelationship}
                name="internal_company_id"
                variant="outlined"
                onChange={(e) => handleChangeEmployeeId(e.target.value)}
                /* helperText={
                  formik.touched.internal_company_id && Boolean(formik.errors.internal_company_id) && formik.errors.internal_company_id
                } */
                onBlur={formik.handleBlur}
                error={formik.touched.internal_company_id && Boolean(formik.errors.internal_company_id)}
                value={formik.values.internal_company_id}
                disabled={formik.values.employee_families === ''}
              />
              <span className={classes.spanFormStep}>{`Ingresa tu ${selectedRelationship.toLocaleLowerCase()}`} </span>
            </Grid>

              {

                (customLabel?.length >= 1) &&
                
                  customLabel?.map((customInput,index) =>(
                    customInput?.showOnSignUp &&
                    <Grid item xs={12} key={index*99}>
                      <TextField
                        required={customInput.showOnSignUp}
                        inputLabelProps={{ shrink: true, required: !customInput.requeridOnSignUp }}
                        type={customInput?.dataType ? customInput?.dataType : 'text'}
                        fullWidth
                        placeholder={customInput?.label}
                        label={((customInput?.dataType && customInput?.dataType == 'date') ? '' : customInput?.label)}
                        name={customInput?.name}
                        variant="outlined"
                        onChange={(e) => handleChangeCustomLabel(e?.target?.name,e.target.value)}
                        onBlur={formik.handleBlur}
                        value={values[customInput?.name] ? values[customInput?.name] : ''}
                        error={Boolean(errors[customInput?.name])}
                        helperText={errors[customInput?.name] ? formik?.errors[customInput?.name] : null }
                      />
                      <span className={classes.spanFormStep}>{customInput?.label}</span>
                    </Grid>
                  ))
              }
          </Grid>
        </ThemeStyles>
      </form>
      <Typography variant="overline">
        Paso 1 de 3
      </Typography>
    </div>
  );
}
