import React, { useContext } from "react";
import { store } from '../../context/store';
import GeneralButton from '../GeneralButton';
import {WELCOME_STEP,ACCOUNT_STEP,THANKYOU_STEP,DATA_STEP,actionsForNextButton,actionsForBackButton, COMPANY_STEP, hideEmail} from '../../lib/signup';
import { existPhoneNumber } from "../../lib/api";
import { validatePhoneSignup } from "../../lib/collections";
import { closeSnackbar, isLoading, openSnackbar } from "../../context/actions";

export const SignupStepButtons = ({classes,formik}) => {

  const { state, dispatch } = useContext(store);
  const {signupReducer: {activeStep,isFormValid,formInfo,},applicationReducer: {loading}} = state;

  function handleSubmit() {
    formik.submitForm();
  }

  const handleNext = async() => {
    
    if(activeStep?.name === DATA_STEP){
      
      isLoading(dispatch, { loading: true });

      const validatePhoneNumber = {
        user_phone:formik?.values?.user_phone?.trim(),
        calling_country_code:formik?.values?.calling_country_code?.trim()
      }
      const existNumber = await validatePhone(validatePhoneNumber);
      if(existNumber?.data){
        if(existNumber?.data?.foundUser && existNumber?.data?.email){
          const hiddenEmail = hideEmail(existNumber?.data?.email)
          //open snackbar
          openSnackbar(dispatch, {
            autoHideDuration: 3000,
            isOpen: true,
            email:existNumber?.data?.email,
            message: `El número de teléfono ingresado ya existe en nuestros registros asociado al correo ${hiddenEmail}.`,
            severity: 'info'
          });
        }
        if(existNumber?.data?.foundUser && !existNumber?.data?.email){
          //open snackbar
          openSnackbar(dispatch, {
            isOpen: true,
            message: `El número de teléfono que ingresaste ya existe en nuestros registros pero no está asociado a ningún perfil.`,
            severity: 'error'
          });
        }
      };

    }

    actionsForNextButton({
      stepName: activeStep?.name,
      dispatch: dispatch,
      isFormValid: isFormValid[activeStep?.name],
      userInfo: formInfo
    });

    isLoading(dispatch, { loading: false });

  }

  const validatePhone = async(validatePhoneNumber) =>{
    try {
     return await existPhoneNumber(validatePhoneSignup,validatePhoneNumber);
    } catch (error) {
      console.error({error})
      return 'Error'
    }
  }

  const handleBack = () => {

    if((activeStep?.name === DATA_STEP) || (activeStep?.name === COMPANY_STEP)){
     formik?.resetForm()
    }

    actionsForBackButton({
      stepName: activeStep?.name,
      dispatch: dispatch
    });
    closeSnackbar(dispatch);
  }

  const isSubmitDisabled = () => {
    if (loading) {
      return true;
    } else {
      return !isFormValid[activeStep?.name];
    }
  }
  

  return (
    <div className={classes.buttonsContainer}>
      {activeStep?.name === THANKYOU_STEP || activeStep?.name === WELCOME_STEP ? (
        null
      ) : (
        <>
          <GeneralButton
            button={{
              text: "Atrás",
              type: "back",
              func: () => handleBack()
            }} />
          {activeStep.name === ACCOUNT_STEP ? (
            <GeneralButton
              disabled={isSubmitDisabled()}
              button={{
                text: loading ? 'Cargando' : 'Finalizar',
                type: "go",
                func: () => handleSubmit()
              }} />
          ) : (
            <GeneralButton
              disabled={!isFormValid[activeStep.name]}
              button={{
                text: "Siguiente",
                type: "go",
                func: () => handleNext()
              }} />
          )
          }
        </>
      )}
    </div>
  )
}