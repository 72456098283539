import React, { useContext } from "react";
import { Button, Divider, Grid, Link, Typography } from "@material-ui/core";
import GeneralButton from '../GeneralButton';
import { signupStyles } from "./styles";
import { store } from '../../context/store';
import { actionsForNextButton } from '../../lib/signup';
import { useRouter } from "next/router";

export const WelcomeStep = () => {
  const { state, dispatch } = useContext(store);
  const classes = signupStyles();
  const router = useRouter();

  const {
    signupReducer: {
      activeStep,
      isFormValid,
      formInfo,
    },
  } = state;

  const handleInit = () => {
    actionsForNextButton({
      stepName: activeStep.name,
      dispatch: dispatch,
      isFormValid: isFormValid[activeStep.name],
      userInfo: formInfo
    })
  }

  const Logo = () => {
    return <img className={classes.bigImageFirstContainer} src={`${process.env.CDN_HOST}/vinco_logo.png`} />
  }

  const Title = () => {
    return <Typography className={classes.titleFirst}  component="h1" variant="h5">
        Crea tu cuenta gratuita
      </Typography>
  }

  const Subtitle = () => {
    return <Typography className={classes.subTitleFirst} variant="overline" gutterBottom>
        Accede a programas de preparatoria, carrera profesional, educación continua, idiomas ¡y más!
      </Typography>
  }

  const StartRegistrationButton = () => {
    return <GeneralButton button={{ text: "Crear cuenta de Vinco", type: "go", func: handleInit }} />
  }

  const LoginLink = () => {
    return <Grid container justifyContent="center">
        <Grid item className={classes.loginLink}>
          <Typography className={classes.loginLinkText}>
            Si ya tienes cuenta{` `}
          </Typography>          
          <Button className={classes.welcomeButton} onClick={() => router.push('/login')} >
            Iniciar sesión aquí            
          </Button>
        </Grid>
      </Grid>
  }

  return (
    <div className={classes.centerContainer}>
      <Logo />
      <Title />
      <Subtitle />
      <StartRegistrationButton handleInit={handleInit} />
      <Divider className={classes.divider} />
      <LoginLink />
    </div>
  )
}