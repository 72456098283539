import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, TextField, Checkbox, Link, withStyles,
  InputAdornment, IconButton } from "@material-ui/core";
import { theme as themeStyles } from "../../styles/theme-constants";
import { ThemeStyles } from "../themeStyles";
import Alert from "@material-ui/lab/Alert";
import { PasswordStrengthRow } from "../PasswordStrengthRow";
import { signupStyles } from "./styles";

import { store } from "../../context/store";
import { ACCOUNT_STEP, allValuesInFormAreValid } from "../../lib/signup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { closeSnackbar } from "../../context/actions";
import { supportCoaches } from "../../constants/config";

const CustomCheckbox = withStyles({
  root: {
    color: '#AFBCED',
    '&$checked': {
      color: '#009888',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const AccountStep = ({ formik, userConfig}) => {
  const { state, dispatch } = useContext(store);

  const {applicationReducer: { snackbarNotification }} = state;

  const classes = signupStyles();

  //Show password input
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const formHasErrors = Boolean(formik.errors.username)
      || Boolean(formik.errors.email)
      || Boolean(formik.errors.password)
      || Boolean(formik.errors.isTermsAccepted);

    allValuesInFormAreValid({
      dispatch,
      formHasErrors: formHasErrors,
      formName: ACCOUNT_STEP,
    });
  }, [formik.errors]);

  const setFieldWithLowerCase = (event) => {
    const lowerCaseValue = event.target.value.toLowerCase();;
    formik.setFieldValue(event.target.name, lowerCaseValue);
  }

  const closeAlert = () =>{
    closeSnackbar(dispatch);
  }

  const handleSend = () => {


    const messageWhats = `Hola, me gustaría recuperar mi usuario y contraseña del correo: ${snackbarNotification?.email}`;
    window.open(`https://wa.me/${supportCoaches}?text=${messageWhats}`)
}

  return (
    <div style={{ minHeight: '70vh' }}>
      <div className={classes.paddedLogoContainer}>
        <img className={classes.imageContainer} src={`${process.env.CDN_HOST}/vinco_logo.png`} />
      </div>
      <Typography component="h1" variant="h5" style={{ paddingTop: '10%' }}>
        Por último, crea tu usuario único y una contraseña segura
      </Typography>
      <form noValidate style={{ marginBottom: '1rem', marginTop: '2rem' }}>
        <ThemeStyles color={themeStyles.secondary}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="off"
                placeholder="rgbolanos8@gmail.com"
                InputLabelProps={{
                  shrink: true, classes: {
                    root: classes.awesomeLabel
                  }
                }}
                onChange={(e) => setFieldWithLowerCase(e)}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.email && Boolean(formik.errors.email) && formik.errors.email
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true, classes: {
                    root: classes.awesomeLabel
                  },
                }}
                InputProps={{
                  endAdornment: (
                  <InputAdornment position="end">
                      <span className={classes.showPassword}>
                      {showPassword ? "Ocultar" : "Mostrar"}
                      </span>
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  )
                }}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                onBlur={formik.handleBlur}
              />
              <PasswordStrengthRow password={formik.values.password} />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <CustomCheckbox 
                className={classes.checkBoxColor} 
                checked={formik.values.isTermsAccepted}
                onChange={formik.handleChange} 
                name="isTermsAccepted" />
              <div>
                <span> He leído y acepto los </span>
                <Link color="secondary" href="https://www.vincoed.com/VincoedT&C.pdf" target="_blank">términos y condiciones</Link>
                <span> y </span>
                <Link color="secondary" href="https://www.vincoed.com/aviso-privacidad.pdf" target="_blank">aviso de privacidad</Link>
              </div>
            </Grid>
          </Grid>
        </ThemeStyles>
      </form>
      <Typography variant="overline">
        Paso 3 de 3
      </Typography>
      {(snackbarNotification?.isOpen) &&
        <> 
         <Alert severity={snackbarNotification?.severity} onClose={closeAlert} style={{alignItems: 'center'}}>
            {
              snackbarNotification?.message.includes('teléfono ingresado ya existe')
                ? <p>{snackbarNotification?.message} Puedes recuperar tu<Link style={{ color: '#0da893' }} href="/forgot-password"> usuario y contraseña </Link> 
                  o contactar a uno de <Link style={{ color: '#0da893' }} onClick={handleSend}>nuestros coaches</Link>.</p>
                : snackbarNotification?.message
              }
          </Alert>
        </>
      }
      {(snackbarNotification?.isOpen && snackbarNotification?.email) &&
        <> 
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Link style={{ color: '#0da893' }} href="/forgot-password">
              Recuperar contraseña
            </Link>
          </div>
        </>
      }
    </div>
  )
}
